$default-color: #fff;
$firstTextColor:#777;
$MainTextColor:#1F1F1F;
$fstBgColor:#F0F4FF;
$ScBgColor:#6C95FF;
$ThBgColor:#909090;
$FourBgColor:#F5F5F5;
$FiveBgColor:#909090;
$HeadingColor:#0093DF;
$FiveBgColor:#909090;
$LinkColor:#F0890F;
$TitleMain:#666;
$ScTextColor:#202020;
$SxBgColor:#E1E9FF;
$SvBgColor:#CDD1D1;


//$navbar-text:#777;
//$page-background:#F0F4FF;
//$header-background:#6C95FF;
//$top-panel:#F5F5F5;
//$heading-color:#0093DF;
//$page-text:#1F1F1F;
//$footer-color:#909090;
//$navbar-activ:#F0890F;
//$news-main:#666;
//$news-text:#202020;
//$news-background:#E1E9FF;
//$dropdown-menu:#CDD1D1;


$recommendedBg: $default-color;
$WidgetTopBg: $ScBgColor;
$newsBg:$SxBgColor;
$footerBg:$FiveBgColor;
$TopPanel:$FourBgColor;
$PageBg:$fstBgColor;
$NavbarText:$firstTextColor;
$Link:$LinkColor;




$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  740px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
